// main imports
import * as React from "react";
import { graphql } from "gatsby";

// plugin imports
import { useTranslation } from "react-i18next";
import { Link, useI18next } from "gatsby-plugin-react-i18next";

// style imports
import "./index.scss";

// svg imports
// import Favicon from "../images/favicon.svg";

// components imports
import Layout from "../components/Layout/Layout.js";
import Seo from "../components/Seo/Seo.js";
import { StaticImage } from "gatsby-plugin-image";

// page
export default function IndexPage() {
	const { t } = useTranslation();
	const { language } = useI18next();

	return (
		<Layout>
			<Seo lang={language} title={t("Cafe & Pousada")} />
			<div className="bodyContainer">
				<div className="landingScreen">
					<Link to="/cafe">
						<h2>boutique cafe</h2>
						<StaticImage
							src="../images/landingCafe.jpg"
							alt={t("A cozy cafe interior")}
						/>
					</Link>

					<Link to="/pousada">
						<h2>boutique pousada</h2>
						<StaticImage
							src="../images/landingPousada.jpg"
							alt={t("A warm room interior")}
						/>
					</Link>
				</div>
			</div>
		</Layout>
	);
}

// graphql query
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
